import React from "react";
import Layout from "../components/Layout/Layout";

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className={`container col-12 col-md-8 justify-content-center mb-5`}>
        {/* <Head>
          <title>Terms And Conditions I Maricom Technologies</title>
          <meta
            name="description"
            content="Find All your Tech Solutions Here"
          />
          <link rel="icon" href="/favi.png" />
        </Head> */}
        <div className={`m-0 m-md-5`}>
          <h2 className={`h1 fw-bold`}>Terms and Conditions</h2>
        </div>
        <div className={`m-0 m-md-3`}>
          <p className={`fw-normal`}>
            This page, together with the documents referred to on it, tells
            about our terms and conditions of sale on which we supply our
            products from to you. Please read these terms and conditions
            carefully before ordering any Products from our shop. You should
            understand that by ordering any of our Products, you agree to be
            bound by our terms and conditions, we highly recommend that you keep
            a copy of Terms and Conditions for future reference.
          </p>
        </div>
        <div className={`m-0 m-md-3`}>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Warranty Policy
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Every tangible Product sold at our shop is subjected to a
                  Warranty period with effect from the date of sale
                  <p>
                    Software and Services sold are not subjected to a Warranty
                    period
                  </p>
                  <p>
                    Customer based Product damages listed below are not covered
                    by our Warranty Policy;
                  </p>
                  <p>
                    I. Product mishandling characterized with breakage and
                    associating the product to liquids
                  </p>
                  <p>
                    II. Power Short-Circuit of the Product due to electric
                    surges and fluctuations while the product is plugged in
                    unstable electricity voltages.
                  </p>
                  <p>
                    III. Repairs handled by other technicians other than Maricom
                    Technologies
                  </p>
                  <p>
                    IV. Battery short-life due to over-charging (product kept on
                    plug at 100% battery Full Charge)
                  </p>
                  <p>V. Any other form of Product reckless handling</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Payment Options
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>We accept the payment options below:</p>
                  <p>
                    I. <strong>Mobile Money Payments</strong>: The Customer is
                    free to make payment through our mobile money to our sales
                    delivery people, however the Withdraw charges and taxes must
                    be added to mobile money payment by the customer
                  </p>
                  <p>
                    II. <strong>Post Payment by Bank</strong>: payment is made
                    by bank deposit using a Bank Agent Outlet near you, the
                    Customer is expected to present a copy of the Bank Payment
                    Receipt to our Sales delivery personnel or send the receipt
                    to us by WhatsApp, and then products are delivered to the
                    Customer. Below are our bank details;
                    <br />
                    Bank Name: <strong>Equity Bank</strong>
                    <br />
                    Account Name: <strong>Maricom Technologies Limited</strong>
                    <br />
                    Account Number: <strong>1001201673321</strong>
                  </p>
                  <p>
                    III. <strong>Cash Payment on Delivery</strong>: We allow
                    cash payment on delivery
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Price Discounts
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    We don’t operate Supermarket Fixed Prices, therefore our
                    Customers are always free to talk to us for Price Discounts
                    through the instant WhatsApp Messaging feature found on our
                    main Website and Online Shop. The Final discount Price
                    agreed upon between us and the Customer is what the Customer
                    pays on delivery
                  </p>
                  <p>
                    However the Customer should take note, additional delivery
                    costs such as Mobile Money charges for Mobile Money Payments
                    and delivery charge to a Customer’s physical location are
                    paid separately from the discount price.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
