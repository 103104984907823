import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";

import styles from "../styles/Cart.module.css";

const Promotions = () => {
  const [clicked, setClicked] = useState(false);

  const router = useNavigate();

  const handleLinkClick = () => {
    setClicked(true);
    router("/");
  };

  return (
    <Layout>
      {/* //   <Head>
    //     <title>Promotions I Maricom Technologies</title>
    //     <meta name="description" content="Find All your Tech Solutions Here" />
    //     <link rel="icon" href="/favi.png" />
    //   </Head> */}

      <div className={`container p-5`}>
        <p className={`text-center mx-auto mb-5 ${styles.no_content}`}>
          No Promotions at this Time.
        </p>

        <p className={`text-center mx-auto`}>
          Promotions are launching very soon
        </p>
        <div className={`text-center mx-auto mb-5`}>
          <button className="btn btn-dark" onClick={() => handleLinkClick()}>
            {clicked ? (
              <div className="clearfix">
                <div className="spinner-border float-end" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              "Look through our Products"
            )}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Promotions;
