import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStateValue } from "../config/StateProvider";

import Layout from "../components/Layout/Layout";

const Register = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [, dispatch] = useStateValue();

  const [isEmpty, setIsEmpty] = useState(true);
  const [user, setUser] = useState({
    fullName: "",
    location: "",
    email: "",
    officeDelivery: true,
    phoneNumber: "",
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("maricom-tech-user-info"));
    setUser(user);
  }, []);

  const handleChange = (id, value) => {
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsEmpty(
      Object.values(user).every(
        (x) => x === null || x === undefined || x === ""
      )
    );

    if (isEmpty === false) {
      setChecked(true);
      localStorage.setItem("maricom-tech-user-info", JSON.stringify(user));
      dispatch({
        type: "SET_USER",
        user: user,
      });

      navigate("/invoice");
    }
  };

  return (
    <Layout>
      {/* <Head>
        <title>Delivery Info I Maricom Technologies</title>
        <meta name="description" content="Find All your Tech Solutions Here" />
        <link rel="icon" href="/favi.png" />
      </Head> */}
      <div className="container">
        <div className="w-50 mx-auto my-3">
          <h3>Finish Up with your Delivery Information</h3>
          <form>
            <div className="mb-3">
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  aria-describedby="nameHelp"
                  required
                  defaultValue={user?.fullName}
                  onChange={(e) => handleChange(e.target.id, e.target.value)}
                />
                <div className="invalid-feedback">
                  Please Fill in this Field.
                </div>
                <div id="nameHelp" className="form-text">
                  Please Fill In your Name
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="location" className="form-label">
                Your Location
              </label>
              <input
                type="text"
                className="form-control"
                id="location"
                aria-describedby="locationHelp"
                required
                defaultValue={user?.location}
                onChange={(e) => handleChange(e.target.id, e.target.value)}
              />
              <div id="locationHelp" className="form-text">
                Your General Area Location
              </div>
            </div>

            <p>Your Delivery Preferences</p>
            <div
              className="btn-group btn-group-sm mb-3"
              role="group"
              aria-label="Pickup Product from Office or Choose Delivery"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                onClick={() =>
                  setUser((state) => ({ ...state, officeDelivery: true }))
                }
                defaultChecked={user?.officeDelivery}
              />
              <label
                className={`btn btn-delivery ${
                  user?.officeDelivery ? "btn-delivery-active" : ""
                }`}
                htmlFor="btnradio1"
              >
                Pickup Item(s) from Our Office
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
                autoComplete="off"
                onClick={() =>
                  setUser((state) => ({ ...state, officeDelivery: false }))
                }
                defaultChecked={!user?.officeDelivery}
              />
              <label
                className={`btn btn-delivery ${
                  user?.officeDelivery ? "" : "btn-delivery-active"
                }`}
                htmlFor="btnradio2"
              >
                Prefer Above Delivery to your Location
              </label>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                required
                defaultValue={user?.email}
                onChange={(e) => handleChange(e.target.id, e.target.value)}
              />
              <div id="emailHelp" className="form-text">
                We will never share your email with anyone else.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                aria-describedby="phoneNumberHelp"
                required
                defaultValue={user?.phoneNumber}
                onChange={(e) => handleChange(e.target.id, e.target.value)}
              />
              <div id="phoneNumberHelp" className="form-text">
                A phone Number To contact You
              </div>
            </div>
            {isEmpty === false ? (
              <p className="text-danger">
                Check All Fields Are Filled in Correctly
              </p>
            ) : (
              ""
            )}
            <button
              type="submit"
              className="btn btn-success btn-lg"
              onClick={(e) => handleOnSubmit(e)}
            >
              {checked ? (
                <div className="clearfix">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
