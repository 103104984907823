import React from "react";

import styles from "../../styles/Header.module.css";

import { useStateValue } from "../../config/StateProvider";

import MobileDrawer from "../MobileDrawer";
import SearchModal from "./SearchModal";

const Hambarger = ({ cartNumber, wishes }) => {
  return (
    <div className="container d-flex justify-content-between mb-3">
      <div className="p-2">
        <button
          className="btn btn-light"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <span className={styles.offCanvasMenu}>Menu</span>
        </button>
      </div>
      <div className="p-2">
        <a href="/cart" className="btn btn-light me-1 nav_a">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-heart"
            viewBox="0 0 16 16"
          >
            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
          </svg>{" "}
          <span className={styles.count}>{wishes}</span>
        </a>
        <a href="/cart" className={`btn btn-light ms-1 nav_a`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-cart"
            viewBox="0 0 16 16"
          >
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
          {"  "}
          <span className={styles.count}>{cartNumber}</span>
        </a>
      </div>
    </div>
  );
};

const Header = () => {
  const [{ cart, wishes }] = useStateValue();
  return (
    <>
      <div id="topbar" className={`d-none d-md-block ${styles.topbar}`}>
        <div className="row">
          <div className="col-md-6">
            <a href="/terms-and-conditions" className={styles.anchor}>
              See our Terms and Conditions
            </a>
          </div>
          <div className="col-md-6">
            <ul id="topbar-menu" className={styles.topbar_menu}>
              <li className={styles.topbar_menu_li}>
                <a href="/cart" className={styles.anchor}>
                  MY CART
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Visible in Smaller Screens */}
      <div className="d-lg-none bg-light bg-gradient">
        <Hambarger
          cartNumber={cart === null ? "0" : cart?.length}
          wishes={wishes === null ? "0" : wishes?.length}
        />
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
        data-bs-backdrop="false"
      >
        <MobileDrawer cartNumber={cart === null ? "0" : cart?.length} />
      </div>

      {/* Only Visible in Large Screens */}
      <div className="d-none d-lg-block bg-light bg-gradient">
        <div className="container-xl">
          <header id="masthead" className={styles.header} role="banner">
            <div className={styles.header_container}>
              <div
                className="row"
                style={{
                  margin: "0",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <div className="col-sm-2 justify-self-center mx-auto">
                  <div className={styles.site_branding}>
                    <a href="/" className={styles.logo}>
                      <img alt="" src="/logo.png" height={50} width={200} />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="row bg-light bg-gradient"
                style={{
                  margin: "0",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <div className={`col-lg-1 search_site_container`}>
                  {/* <!-- Button trigger modal --> */}
                  <div className={styles.search_site}>
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#searchModal"
                      className={`btn ${styles.nav_a}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        <use xlinkHref="#search" />
                      </svg>
                    </button>
                  </div>
                  {/* <!-- Search Modal --> */}
                  <div
                    className="modal fade"
                    id="searchModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <SearchModal />
                  </div>
                </div>
                <nav className={`main_navigation col-sm-9`}>
                  <ul className={styles.nav_menu} style={{ paddingLeft: 0 }}>
                    <li className={styles.nav_li}>
                      <a className={styles.nav_a} href="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-house-door-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z" />
                        </svg>
                      </a>
                    </li>
                    <li className={styles.nav_li}>
                      <a href="/computers" className={styles.nav_a}>
                        Computers
                      </a>
                    </li>
                    <li id="menu-item-4636" className={styles.nav_li}>
                      <a href="/accessories" className={styles.nav_a}>
                        Accessories
                      </a>
                    </li>
                    <li id="menu-item-4623" className={styles.nav_li}>
                      <a href="/equipment" className={styles.nav_a}>
                        Equipment
                      </a>
                    </li>
                    <li id="menu-item-25277" className={styles.nav_li}>
                      <a href="/software" className={styles.nav_a}>
                        Software
                      </a>
                    </li>
                    <li id="menu-item-22862" className={styles.nav_li}>
                      <a href="/promotions" className={styles.nav_a}>
                        Promotions
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="col">
                  <ul
                    className={styles.header_right}
                    style={{ marginRight: 0, paddingRight: 0, paddingLeft: 0 }}
                  >
                    <li className={styles.item_wishlist}>
                      <a href="/cart" className={styles.count_a}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-heart"
                          viewBox="0 0 16 16"
                        >
                          <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                        </svg>{" "}
                        <span
                          className={`count wishlist-counter ${styles.count}`}
                        >
                          {wishes === null ? "0" : wishes?.length}
                        </span>
                      </a>
                    </li>
                    <li className={styles.item_cart}>
                      <a href="/cart" className={styles.count_a}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-cart"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>{" "}
                        <span className={`count cart-counter  ${styles.count}`}>
                          {cart === null ? "0" : cart?.length}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default Header;
