import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import Slider from "../../components/Show/Slider";
import Description from "../../components/Show/PhoneDescription";
import Summary from "../../components/Show/Summary";
import sanityClient from "../../config/sanity";

import Layout from "../../components/Layout/Layout";
import styles from "../../styles/Show.module.css";

const PhoneSlug = () => {
  const [product, setProduct] = useState(null);
  const { slug } = useParams();

  // Getting Link to Category
  const location = useLocation();
  const link = location.pathname;
  const newLink = link.substring(link.indexOf("/") + 1, link.lastIndexOf("/"));

  useEffect(() => {
    sanityClient
      .fetch(`*[ _type == "phone" &&  slug.current == $slug][0]`, { slug })
      .then((data) => setProduct(data))
      .catch(console.error);
  }, [slug]);

  if (!product) {
    return (
      <div className="square-box d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status"></div>
        Loading...
      </div>
    );
  }

  return (
    <Layout>
      {/* <Head>
        <title>{item?.name} I Maricom Technologies</title>
        <meta name="description" content="Find All your Tech Solutions Here" />
        <link rel="icon" href="/favi.png" />
      </Head> */}
      <hr />
      <div className="row" style={{ margin: "2rem 0" }}>
        <div className={`col-lg-6`}>
          <Slider images={product?.images} />
        </div>
        <div className={`col-lg-6 ${styles.summary}`}>
          <Summary
            slug={product?.slug?.current}
            name={product?.name}
            price={product?.price}
            image={product?.images[0]?.asset?._ref}
            stock={product?.stock}
            link={newLink}
            id={product?._id}
          />
        </div>
      </div>
      <hr />
      <Description item={product} />
    </Layout>
  );
};

export default PhoneSlug;
