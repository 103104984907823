import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import { urlFor } from "../../config/sanity";
import { useStateValue } from "../../config/StateProvider";
import styles from "../../styles/Show.module.css";

const Summary = ({ slug, id, name, price, stock, image }) => {
  const [, dispatch] = useStateValue();
  const [counter, setCounter] = useState(1);
  const [wishlist, setWishlist] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const outOfStock = stock < 1;

  const incrementCounter = () => {
    if (counter < 5) setCounter(counter + 1);
  };

  const decrementCounter = () => {
    if (counter > 1) setCounter(counter - 1);
  };

  const toggleWishlist = () => {
    setWishlist(!wishlist);
    if (wishlist === false) {
      addToWishes();
    }
  };

  function addToCart() {
    setIsPopoverOpen(true);
    setTimeout(() => {
      setIsPopoverOpen(false);
    }, 2000);

    // dispatch item to data layer
    dispatch({
      type: "ADD_TO_CART",
      item: {
        id: id,
        slug: slug,
        title: name,
        image: urlFor(image).url(),
        qty: counter,
        price: price,
        total: price * counter,
      },
    });
  }

  function addToWishes() {
    // dispatch item to data layer
    dispatch({
      type: "ADD_TO_WISHES",
      item: {
        id: id,
        slug: slug,
        title: name,
        image: urlFor(image).url(),
        price: price,
      },
    });
  }

  return (
    <>
      <h1 className={`${styles.product_title}`}>{name}</h1>
      {outOfStock ? (
        <p className={`${styles.price}`}>
          <span className={`${styles.amount}`}>
            <b>Out of Stock</b>
          </span>
        </p>
      ) : (
        <p className={`${styles.price}`}>
          <span className={`${styles.amount}`}>
            UGX <b>{new Intl.NumberFormat().format(price)}</b>
          </span>
        </p>
      )}
      <div className="row" style={{ margin: 0 }}>
        <div className={`col-md-6 col-sm-12`}>
          <div className={`${styles.quantity}`}>
            <button
              className={styles.form_btn}
              onClick={() => decrementCounter()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-dash"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </button>
            <span className={styles.form_input} title="Qty">
              {counter}
            </span>
            <button
              className={styles.form_btn}
              onClick={() => incrementCounter()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus"
                viewBox="0 0 16 16"
              >
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </button>
          </div>
        </div>
        <div className={`col-md-6 col-sm-12`}>
          {!outOfStock ? (
            <Popover
              isOpen={isPopoverOpen}
              positions={["top"]} // preferred positions by priority
              content={
                <div className="btn btn-success mb-2">Added to Cart.</div>
              }
            >
              <button
                className={`btn btn-dark btn-lg ${styles.cart_btn}`}
                onClick={() => addToCart()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-cart"
                  viewBox="0 0 16 16"
                  style={{ marginTop: "-5px", marginRight: "8px" }}
                >
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
                {"  "}
                Add to cart
              </button>
            </Popover>
          ) : (
            <button
              className={`btn btn-dark btn-lg ${styles.cart_btn}`}
              disabled={outOfStock}
              title="Item is out of Stock"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-cart"
                viewBox="0 0 16 16"
                style={{ marginTop: "-5px", marginRight: "8px" }}
              >
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              {"  "}
              Add to cart
            </button>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4 mb-3">
        <a
          target="_blank"
          href="https://web.whatsapp.com/send?phone=256784738098&text=Hello MTL Tech-Shop"
          className={`${styles.whats_app_button}`}
          rel="noopener noreferrer"
        >
          <div className={`${styles.whats_app_cs_img}`}>
            <div
              className={`${styles.whats_app_cs_img_wrap}`}
              style={{
                background: "url() center center no-repeat",
                backgroundSize: "cover",
              }}
            />
          </div>
          <div className={`${styles.whats_app_btn_txt}`}>
            <div className={`${styles.whats_app_cs_info}`}>
              <div className={`${styles.whats_app_cs_name}`}>
                Maricom Technologies
                <span className={`${styles.whats_app_cs_status}`}>Online</span>
              </div>
            </div>
            <div className={`${styles.whats_app__btn_title}`}>
              Click here to Chat with us On WhatsApp
            </div>
          </div>
        </a>
      </div>
      <span
        className={`${styles.wishlist_btn}`}
        onClick={() => toggleWishlist()}
      >
        {!wishlist ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={`bi bi-heart ${styles.like}`}
            viewBox="0 0 16 16"
          >
            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={`bi bi-heart-fill ${styles.liked}`}
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
            />
          </svg>
        )}
        <span className={`${styles.indent_text}`}>Add to wishlist</span>
      </span>
      <div className={`${styles.product_meta}`}>
        <div className={`${styles.product_share}`}>
          <span className={`${styles.screen_reader_text}`}>Share</span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/maricomtech"
            className={`${styles.share_link}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-facebook"
              viewBox="0 0 16 16"
            >
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
            </svg>
            <span className={`${styles.screen_reader_text}`}>Facebook</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.twitter.com/MaricomTechnol1"
            className={`${styles.share_link}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-twitter"
              viewBox="0 0 16 16"
            >
              <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
            </svg>
            <span className={`${styles.screen_reader_text}`}>Twitter</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/maricom-technologies"
            className={`${styles.share_link}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-linkedin"
              viewBox="0 0 16 16"
            >
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
            </svg>
            <span className={`${styles.screen_reader_text}`}>LinkedIn</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default Summary;
