import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getCartTotal } from "../../config/reducer";
import styles from "../../styles/Cart.module.css";

const SubTotal = ({ cart }) => {
  const [checked, setChecked] = useState(false);
  const router = useNavigate();

  return (
    <div className={`${styles.subtotal}`}>
      <h5>
        Subtotal ({cart?.length} items):{" "}
        <strong>{new Intl.NumberFormat().format(getCartTotal(cart))}</strong>
      </h5>
      <button
        className={`btn btn-dark`}
        onClick={() => {
          router("/register");
          setChecked(true);
        }}
      >
        {checked ? (
          <div className="clearfix">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          "Proceed to Checkout"
        )}
      </button>
    </div>
  );
};

export default SubTotal;
