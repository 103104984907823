import React, { useEffect, useState } from "react";

import Layout from "../components/Layout/Layout";
import Loading from "../components/Layout/Loading";
import ProductSection from "../components/ProductSection";

import sanityClient from "../config/sanity";

import shuffle from "../config/shuffle";

export default function Home() {
  const [allProducts, setAllProducts] = useState(null);

  const getAllProducts = async () => {
    let params =
      "_id, slug, name, price, stock, images, categories[] -> { slug }";
    let compParams =
      "_id, slug, name, price, stalk, images, categories[] -> { slug }";
    let productNo = "0...10";
    let accessoryNo = "0...3";
    let compNo = "0...5";

    let sliceOperation = `[${productNo}]{${params}} | order(_createdAt asc)`;
    let compSliceOperation = `[${compNo}]{${compParams}} | order(_createdAt asc)`;
    let accessorySliceOperation = `[${accessoryNo}]{${params}} | order(_createdAt asc)`;

    const compQuery = `*['Laptops' in categories[]-> title]${compSliceOperation}
                       + *['Desktops' in categories[]-> title]${compSliceOperation}
                      `;
    const softwareQuery = `*['Software' in categories[]-> title]${sliceOperation}`;
    const accessoriesQuery = `
                              *['Computer Accessories' in categories[]-> title]${accessorySliceOperation} 
                              + *['Network Accessories' in categories[]-> title]${accessorySliceOperation} 
                              + *['Accessories' in categories[]-> title]${accessorySliceOperation}
                            `;
    const equipmentQuery = `*['Equipment' in categories[]-> title]${sliceOperation}`;

    const computers = await sanityClient.fetch(compQuery);
    const software = await sanityClient.fetch(softwareQuery);
    const accessories = await sanityClient.fetch(accessoriesQuery);
    const equipment = await sanityClient.fetch(equipmentQuery);

    let props = {
      software: software.length ? software : [],
      computers: computers.length ? shuffle(computers) : [],
      accessories: accessories.length ? accessories : [],
      equipment: equipment.length ? equipment : [],
    };

    setAllProducts(props);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      <Layout>
        <div
          className={`d-flex gap-1 flex-column flex-lg-row container-lg w-100 mt-3`}
        >
          <div className={`d-flex gap-1 flex-row flex-lg-column mx-auto`}>
            <div>
              <a href="/accessories">
                <img
                  className={`zoom_image`}
                  src="/accessories.png"
                  alt="Accessories"
                />
              </a>
            </div>
            <div>
              <a href="/equipment">
                <img
                  className={`zoom_image`}
                  src="/equipment.png"
                  alt="Equipment"
                />
              </a>
            </div>
          </div>
          <div className=" mx-auto">
            <a href="/computers">
              <img
                className={`main_zoom_image`}
                src="/Computers.PNG"
                alt="Computer"
              />
            </a>
          </div>
          <div className={`d-flex gap-1 flex-lg-column flex-row mx-auto`}>
            <div>
              <a href="/software">
                <img
                  className={`zoom_image`}
                  src="/software.png"
                  alt="Software"
                />
              </a>
            </div>
            <div>
              <a href="/promotions">
                <img
                  className={`zoom_image`}
                  src="/promotions.png"
                  alt="Promotions"
                />
              </a>
            </div>
          </div>
        </div>

        <div className={`container-lg mt-5`}>
          <div className={`mb-3`}>
            {allProducts ? (
              <ProductSection
                title="ACCESSORIES"
                items={allProducts?.accessories}
                id="accessories"
              />
            ) : (
              <Loading text="Accessories" />
            )}
            {allProducts ? (
              <ProductSection
                title="SOFTWARE"
                items={allProducts?.software}
                id="software"
              />
            ) : (
              <Loading text="Software products" />
            )}
            {allProducts ? (
              <ProductSection
                title="COMPUTERS"
                items={allProducts?.computers}
                id="computers"
              />
            ) : (
              <Loading text="Computers" />
            )}
            {allProducts ? (
              <ProductSection
                title="EQUIPMENT"
                items={allProducts?.equipment}
                id="equipment"
              />
            ) : (
              <Loading text="Equipment" />
            )}
          </div>
          <div className={`row`} style={{ margin: 0 }}>
            <div className={`col-md-6 container`}>
              <h2 className={`about_head`}>
                The trusted Online Computer Technology Shop with affordable
                prices and excellent after sales services
              </h2>
              <p className={`about_p`}>
                tech-shop.maricomtech.com is an online Computer Technology Shop
                based in Kampala, Uganda. The Shop provides technology products
                in Accessories, Software, Computers and Equipment at affordable
                prices. When you shop with us, you are guaranteed of the newest
                trends and best deals in technology. Always feel free to check
                on us at our shop online or physically at our location in
                Kampala City Centre, to never miss out on price discounts,
                deals, new arrivals and promotions. If you have any questions,
                send us an email at info@maricomtech.com or call us at
                +256784738098. Enjoy shopping computer technology products with
                Maricom Technologies.
                <span role="img" aria-label="Smiley Face Emoji">
                  🙂
                </span>
              </p>
            </div>
            <div className={`col-md-6 container`}>
              <h2 className={`about_head`}>
                The One-Stop Computer Technology Shop for Accessories, Software,
                Computers and Equipment.
              </h2>
              <p className={`about_p`}>
                Maricom Technologies Computer Technology shop is truly your
                one-stop shopping destination for all your Technology needs.
                From as small as Ethernet RJ 45 Connectors to heavy duty
                Computers and Equipment, we have got your back! Select from our
                variety of Products at the comfort of your location by use of a
                Smartphone, Tablet or Computer. No technology skill is complete
                without Computer technology. Feel great with Maricom
                Technologies.
              </p>
            </div>
          </div>
        </div>
        <script
          src="https://cdn.jsdelivr.net/npm/@glidejs/glide"
          defer
        ></script>
      </Layout>
    </>
  );
}
