import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Static Pages
import Landing from "./pages/index";
import About from "./pages/about";
import Cart from "./pages/cart";
import ContactUs from "./pages/contact-us";
import DeliveryInfo from "./pages/delivery-info";
import Faqs from "./pages/faqs";
import TermsAndConditions from "./pages/terms-and-conditions";
import Promotions from "./pages/promotions";
import Register from "./pages/register";
import Invoice from "./pages/invoice";
import Search from "./pages/Search";

// Dynamic Pages
import Phones from "./pages/phones";
import Computers from "./pages/computers";
import Desktops from "./pages/desktops";
import Software from "./pages/software";
import Equipment from "./pages/equipment";
import NetAccessories from "./pages/network-accessories";
import Accessories from "./pages/accessories";

// Slug Pages
import SearchID from "./pages/Slugs/SearchID";
import PhoneSlug from "./pages/Slugs/PhoneSlug";
import ProductSlug from "./pages/Slugs/ProductSlug";
import ComputerSlug from "./pages/Slugs/ComputerSlug";

// 404 Page
import NotFoundPage from "./pages/NotFoundPage";

// add bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} exact />
        <Route path="/about" element={<About />} exact />
        <Route path="/register" element={<Register />} exact />
        <Route path="/cart" element={<Cart />} exact />
        <Route path="/invoice" element={<Invoice />} exact />
        <Route path="/contact-us" element={<ContactUs />} exact />
        <Route path="/faqs" element={<Faqs />} exact />
        <Route path="/delivery-info" element={<DeliveryInfo />} exact />
        <Route path="/promotions" element={<Promotions />} exact />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
          exact
        />

        <Route path="/search" element={<Search />} />
        <Route path="/product/:id" element={<SearchID />} />

        <Route path="/network-accessories" element={<NetAccessories />} exact />
        <Route path="/desktops" element={<Desktops />} exact />
        <Route path="/computers" element={<Computers />} exact />
        <Route path="/equipment" element={<Equipment />} exact />
        <Route path="/software" element={<Software />} exact />
        <Route path="/phones" element={<Phones />} exact />
        <Route path="/accessories" element={<Accessories />} exact />

        <Route path="/network-accessories/:slug" element={<ProductSlug />} />
        <Route path="/accessories/:slug" element={<ProductSlug />} />
        <Route path="/equipment/:slug" element={<ProductSlug />} />
        <Route path="/software/:slug" element={<ProductSlug />} />
        <Route path="/computers/:slug" element={<ComputerSlug />} />
        <Route path="/laptops/:slug" element={<ComputerSlug />} />
        <Route path="/desktops/:slug" element={<ComputerSlug />} />
        <Route path="/phones/:slug" element={<PhoneSlug />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
