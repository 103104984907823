import React from "react";
import { Link } from "react-router-dom";
import { useStateValue } from "../../config/StateProvider";

import styles from "../../styles/Cart.module.css";

const WishProduct = ({ id, slug, image, title, price }) => {
  const [, dispatch] = useStateValue();
  const removeFromWishes = (slug) => {
    //remove item from basket
    dispatch({
      type: "REMOVE_FROM_WISHES",
      slug: slug,
    });
  };
  return (
    <div className={`${styles.checkoutProduct}`}>
      <Link to={`/product/${id}`}>
        <img
          className={`${styles.checkoutProduct__image}`}
          src={image}
          alt=""
        />
      </Link>

      <div className={`${styles.checkoutProduct__info}`}>
        <p className={`${styles.checkoutProduct__title}`}>
          <Link to={`/product/${id}`}>{title}</Link>
        </p>
        <p className={`${styles.checkoutProduct__price}`}>
          <small>UGX</small>
          <strong>{price}</strong>
        </p>
        <div className={`justify-content-space-between`}>
          <Link to={`/product/${id}`} className={`btn btn-link m-1`}>
            View Item
          </Link>
          <button
            className={`btn btn-danger m-1`}
            onClick={() => removeFromWishes(slug)}
          >
            Remove From Wish List
          </button>
        </div>
      </div>
    </div>
  );
};

export default WishProduct;
