import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import SanityClient from "../config/sanity";

import Layout from "../components/Layout/Layout";
import Product from "../components/ProductSearch";

import styles from "../styles/Products.module.css";

const Search = () => {
  const [products, setProducts] = useState(null);

  let [searchParams] = useSearchParams();
  let param = searchParams?.get("itemName");

  useEffect(() => {
    const getPdts = async () => {
      const query = `
    *[_type in ["computer", "product" , "phone"] && name match "${param}*"]
    `;

      await SanityClient.fetch(query).then((result) => setProducts(result));
    };

    getPdts();
  }, [param]);

  // if (!products) {
  //   return (

  //   );
  // }

  return (
    <>
      <Layout>
        <div className="d-flex justify-content-center py-3">
          <h3 className={`${styles.page_title}`}>Search Results for {param}</h3>
        </div>
        <hr />
        <div className={`row px-3`} style={{ margin: 0 }}>
          {products ? (
            products.map((comp, i) => (
              <div className={`col-md-3`} key={i}>
                <Product product={comp} />
              </div>
            ))
          ) : (
            <div className="half-square-box d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status"></div>
              Loading...
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Search;
