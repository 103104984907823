import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import SanityClient from "../../config/sanity";

import Slider from "../../components/Show/Slider";
import Summary from "../../components/Show/Summary";
import CompDescription from "../../components/Show/CompDescription";
import Description from "../../components/Show/Description";
import PhoneDesc from "../../components/Show/PhoneDescription";

import Layout from "../../components/Layout/Layout";
import styles from "../../styles/Show.module.css";

const SearchID = () => {
  const [product, setProduct] = useState(null);

  // Getting Product ID
  const location = useLocation();
  const link = location.pathname;
  const productID = link.substring(link.lastIndexOf("/") + 1);

  useEffect(() => {
    SanityClient.fetch(`*[_id == $productID][0]`, { productID })

      .then((data) => setProduct(data))
      .catch(console.error);
  }, [productID]);

  if (!product) {
    return (
      <div className="square-box d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status"></div>
        Loading...
      </div>
    );
  }

  return (
    <Layout>
      <hr />
      <div className="row" style={{ margin: "2rem 0" }}>
        <div className={`col-lg-6`}>
          <Slider images={product?.images} />
        </div>
        <div className={`col-lg-6 ${styles.summary}`}>
          <Summary
            slug={product?.slug?.current}
            name={product?.name}
            price={product?.price}
            image={product?.images[0]?.asset?._ref}
            stock={product?.stalk}
            id={product?._id}
          />
        </div>
      </div>
      <hr />
      {product?._type === "computer" ? (
        <CompDescription item={product} />
      ) : product?._type === "product" ? (
        <Description item={product} />
      ) : (
        <PhoneDesc item={product} />
      )}
    </Layout>
  );
};

export default SearchID;
