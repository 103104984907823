import React from "react";

import Layout from "../components/Layout/Layout";

const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <div className="page-wrap d-flex flex-row align-items-center my-5">
          <div className="container my-5">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <span className="display-1 d-block">404</span>
                <div className="mb-4 lead">
                  The page you are looking for was not found.
                </div>
                <a href="/" className="btn btn-link">
                  Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NotFoundPage;
