import React from "react";

import styles from "../styles/Footer.module.css";

let whatsAppText;

const handleOnChange = (e) => {
  whatsAppText = e;
};

const handleSubmit = (e) => {
  e.preventDefault();
  if (whatsAppText)
    window.open(`https://wa.me/256784738098/?text=${whatsAppText}`);
};

export const WhatsAppPopup = () => {
  return (
    <div className="me-3">
      <div className={styles.whatsAppPopup}>Type WhatsApp message here</div>
      <div className={styles.whatsAppPopupResponse}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            type="text"
            name="WhatsAppText"
            className={styles.whatsAppPopupResponseInput}
            placeholder="Write a response"
            onChange={(e) => handleOnChange(e.target.value)}
          />
          <button
            type="submit"
            className={`btn ${styles.whatsAppPopupResponseSend}`}
          >
            <img src="/send-img.png" alt="Send Arrow" />
          </button>
        </form>
      </div>
    </div>
  );
};
