import SanityClient from "../config/sanity";

export default async function createOrder({
  user,
  order,
  invoiceNumber,
  invoiceDate,
}) {
  try {
    await SanityClient.config({
      token: process.env.REACT_APP_SANITY_TOKEN,
    }).create({
      _type: "order",
      fullName: user.fullName,
      location: user.location,
      invoiceNumber: invoiceNumber,
      invoiceDate: invoiceDate,
      officePickUp: user.officeDelivery,
      invoicepdf: null,
      phoneNumber: user.phoneNumber,
      email: user.email,
      orderContent: order,
    });
  } catch (err) {
    return console.error(err);
  }
  return console.log({ message: "Order submitted" });
}
