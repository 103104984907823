import React, { useState } from "react";
import { Popover } from "react-tiny-popover";

import { urlFor } from "../config/sanity";
import { useStateValue } from "../config/StateProvider";

import styles from "../styles/Products.module.css";

const Product = ({ product }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [, dispatch] = useStateValue();

  const slug = product?.slug?.current;
  let link = product?.categories[0].slug.current;
  if(link === 'computer-accessories'|| link === 'network-accessories'){
    link = 'accessories';
  };
  const stock = product?.stock ? product?.stock : product?.stalk;
  const [wishlist, setWishlist] = useState(false);
  const [style, setStyle] = useState({ display: "none" });
  const toggleWishlist = () => {
    setWishlist(!wishlist);
    addToWishes();
  };

  function addToCart() {
    setIsPopoverOpen(true);
    setTimeout(() => {
      setIsPopoverOpen(false);
    }, 2000);

    // dispatch item to data layer
    dispatch({
      type: "ADD_TO_CART",
      item: {
        id: product._id,
        slug: slug,
        title: product.name,
        image: urlFor(product.images[0].asset._ref).url(),
        qty: 1,
        price: product.price,
        total: product.price,
      },
    });
  }

  function addToWishes() {
    // dispatch item to data layer
    dispatch({
      type: "ADD_TO_WISHES",
      item: {
        slug: slug,
        id: product._id,
        title: product.name,
        image: urlFor(product.images[0].asset._ref).url(),
        price: product.price,
      },
    });
  }

  return (
    <div>
      <div
        className={`${styles.product_header}`}
        onMouseEnter={() => {
          setStyle({ display: "block" });
        }}
        onMouseLeave={() => {
          setStyle({
            display: "none",
            transition: "width 1s, height 1s, transform 1s ease",
          });
        }}
      >
        <a
          href={`/${link}/${slug}`}
          className={`${styles.product_header_a}  text-decoration-none`}
        >
          <img
            width={300}
            height={300}
            src={urlFor(product?.images[0]?.asset._ref).url()}
            className={`${styles.product_header_img}`}
            alt="..."
          />
        </a>
        <div className={`${styles.product_header_btn_section}`}>
          <span onClick={() => toggleWishlist()}>
            {!wishlist ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className={`bi bi-heart ${styles.like}`}
                viewBox="0 0 16 16"
              >
                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className={`bi bi-heart-fill ${styles.liked}`}
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                />
              </svg>
            )}
            <span className={`visually-hidden`}>Add to wishlist</span>
          </span>
        </div>
        <div className={`${styles.buttons}`} style={style}>
          {stock > 0 ? (
            <Popover
              isOpen={isPopoverOpen}
              positions={["top"]} // preferred positions by priority
              content={
                <div className="btn btn-success mb-1">Added to Cart.</div>
              }
            >
              <span
                className={`btn btn-dark btn-sm me-2`}
                style={{ borderRadius: "50%" }}
                onClick={() => {
                  addToCart();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-cart"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
              </span>
            </Popover>
          ) : (
            ""
          )}
          <button
            className={`btn btn-dark btn-sm ms-2`}
            style={{ borderRadius: "50%" }}
          >
            <a href={`/${link}/${slug}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-eye text-decoration-none"
                viewBox="0 0 16 16"
              >
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
              </svg>
            </a>
          </button>
        </div>
      </div>
      <a href={`/${link}/${slug}`}>
        <h3
          className={`${styles.product__title} text-center  text-decoration-none`}
        >
          {product?.name}
        </h3>{" "}
      </a>
      {stock > 0 ? (
        <p className={`${styles.price} text-center`}>
          UGX {new Intl.NumberFormat().format(product?.price)}
        </p>
      ) : (
        <p className={`${styles.price} text-center`}>Out Of Stock</p>
      )}
    </div>
  );
};

export default Product;
