import React from "react";
import Layout from "../components/Layout/Layout";

const DeliveryInfo = () => {
  return (
    <Layout>
      <div className={`container col-12 col-md-8 justify-content-center mb-5`}>
        {/* <Head>
        <title>Delivery Info I Maricom Technologies</title>
        <meta name="description" content="Find All your Tech Solutions Here" />
        <link rel="icon" href="/favi.png" />
      </Head> */}
        <div className={`m-5`}>
          <h2 className={`h1 fw-bold`}>Delivery Information</h2>
        </div>
        <div className={`m-3`}>
          <p className={`fw-normal`}>
            Delivery Costs apply for supplies delivered to the Customer address
            pickup location while No Delivery Costs applied for Purchase Orders
            picked by the Customer from our Offices. Check out our delivery
            costs in town centres at our{" "}
            <a
              href="https://tech-shop.maricomtech.com/delivery-notice"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://tech-shop.maricomtech.com/delivery-notice
            </a>
          </p>
          <p className={`fw-normal`}>
            Payment is either cash on delivery or through Mobile Money to our
            field delivery Sales Person, however for Mobile Money Payment, the
            Customer is called upon to include withdraw charges as an addition
            to the Tax Invoice Amount.
          </p>
          <p className={`fw-normal`}>
            See more info on delivery locations, timeline, payment options
            available and delivery cost below :
          </p>
        </div>
        <div className={`m-3`}>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Kampala
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Location</th>
                          <th scope="col" colSpan="4">
                            Delivery Option
                          </th>
                          {/* <th scope="col" colSpan="5">
                            Delivery Fee by weight
                          </th> */}
                        </tr>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Type</th>
                          <th scope="col">Courier</th>
                          <th scope="col">Payment</th>
                          <th scope="col">Delivery Time line (Mon – Sat)</th>

                          {/* <th scope="col">0 – 2 kg </th>
                          <th scope="col">2 – 4 kg </th>
                          <th scope="col">4 – 5 kg </th>
                          <th scope="col">5 – 7.5 kg </th>
                          <th scope="col">7.5 – 10 kg</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Wandegeya</th>
                          <td>Delivery</td>
                          <td>Boda / Vehicle</td>
                          <td>Cash On Delivery</td>
                          <td>3 – 5 working days</td>
                          {/* {/* <td>UGX 500</td> 
                          <td>UGX 1500</td>
                          <td>UGX 2500</td>
                          <td>UGX 3500</td>
                          <td>UGX 4500</td> */}
                        </tr>
                        <tr>
                          <th scope="row">Ntinda</th>
                          <td>Delivery</td>
                          <td>Boda / Vehicle</td>
                          <td>Cash On Delivery</td>
                          <td>3 – 5 working days</td>
                          {/* <td>UGX 1000</td> */}
                          {/* <td>UGX 2000</td> */}
                          {/* <td>UGX 3000</td> */}
                          {/* <td>UGX 4000</td> */}
                          {/* <td>UGX 5000</td> */}
                        </tr>
                        <tr>
                          <th scope="row">Muyenga</th>
                          <td>Delivery</td>
                          <td>Boda / Vehicle</td>
                          <td>Cash On Delivery</td>
                          <td>3 – 5 working days</td>
                          {/* <td>UGX 1000</td> */}
                          {/* <td>UGX 2000</td> */}
                          {/* <td>UGX 3000</td> */}
                          {/* <td>UGX 4000</td> */}
                          {/* <td>UGX 5000</td> */}
                        </tr>
                        <tr>
                          <th scope="row">Kololo</th>
                          <td>Delivery</td>
                          <td>Boda / Vehicle</td>
                          <td>Cash On Delivery</td>
                          <td>3 – 5 working days</td>
                          {/* <td>UGX 1000</td> */}
                          {/* <td>UGX 2000</td> */}
                          {/* <td>UGX 3000</td> */}
                          {/* <td>UGX 4000</td> */}
                          {/* <td>UGX 5000</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Wakiso
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Location</th>
                          <th scope="col" colSpan="4">
                            Delivery Option
                          </th>
                          {/* <th scope="col" colSpan="5">
                            Delivery Fee by weight
                          </th> */}
                        </tr>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Type</th>
                          <th scope="col">Courier</th>
                          <th scope="col">Payment</th>
                          <th scope="col">Delivery Time line (Mon – Sat)</th>

                          {/* <th scope="col">0 – 2 kg </th>
                          <th scope="col">2 – 4 kg </th>
                          <th scope="col">4 – 5 kg </th>
                          <th scope="col">5 – 7.5 kg </th>
                          <th scope="col">7.5 – 10 kg</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Nansana</th>
                          <td>Delivery</td>
                          <td>Boda / Vehicle</td>
                          <td>Cash On Delivery</td>
                          <td>3 – 5 working days</td>
                          {/* <td>UGX 2500</td> */}
                          {/* <td>UGX 3500</td> */}
                          {/* <td>UGX 4500</td> */}
                          {/* <td>UGX 5500</td> */}
                          {/* <td>UGX 6500</td> */}
                        </tr>
                        <tr>
                          <th scope="row">Kisasi</th>
                          <td>Delivery</td>
                          <td>Boda / Vehicle</td>
                          <td>Cash On Delivery</td>
                          <td>3 – 5 working days</td>
                          {/* <td>UGX 3000</td> */}
                          {/* <td>UGX 4000</td> */}
                          {/* <td>UGX 5000</td> */}
                          {/* <td>UGX 6000</td> */}
                          {/* <td>UGX 7000</td> */}
                        </tr>
                        <tr>
                          <th scope="row">Matuga</th>
                          <td>Delivery</td>
                          <td>Boda / Vehicle</td>
                          <td>Cash On Delivery</td>
                          <td>3 – 5 working days</td>
                          {/* <td>UGX 5000</td> */}
                          {/* <td>UGX 6000</td> */}
                          {/* <td>UGX 7000</td> */}
                          {/* <td>UGX 8000</td> */}
                          {/* <td>UGX 9000</td> */}
                        </tr>
                        <tr>
                          <th scope="row">Entebbe</th>
                          <td>Delivery</td>
                          <td>Boda / Vehicle</td>
                          <td>Cash On Delivery</td>
                          <td>3 – 5 working days</td>
                          {/* <td>UGX 12500</td> */}
                          {/* <td>UGX 13500</td> */}
                          {/* <td>UGX 14500</td> */}
                          {/* <td>UGX 15500</td> */}
                          {/* <td>UGX 16500</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeliveryInfo;
