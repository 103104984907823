import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import CartComponent from "../components/cart/Cart";
import Wishlist from "../components/cart/WishList";
import Layout from "../components/Layout/Layout";

import { useStateValue } from "../config/StateProvider";

import styles from "../styles/Cart.module.css";

const Cart = () => {
  const [active, setActive] = useState(0);
  const [clicked, setClicked] = useState(false);

  const navigate = useNavigate();
  const [{ cart, wishes }] = useStateValue();

  const handleOnClick = (index) => {
    setActive(index); // remove the curly braces
  };

  const handleLinkClick = () => {
    setClicked(true);
    navigate("/");
  };

  return (
    <Layout>
      {/* <Head>
        <title>Your Cart I Maricom Technologies</title>
        <meta name="description" content="Find All your Tech Solutions Here" />
        <link rel="icon" href="/favi.png" />
      </Head> */}
      <div className={`container`}>
        <div className={`${styles.cart_header}`}>
          <h2 className={`${styles.cart_header_title}`}>Cart</h2>
        </div>
        <div className={``}>
          <ul
            className="nav nav-pills mb-3 justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li
              className={`nav-item mx-3 ${styles.description_tab}`}
              role="presentation"
            >
              <a
                className={`${styles.tab_link} active`}
                href="#cart"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={() => handleOnClick(0)}
                style={
                  active === 0 ? { color: "#23232c" } : { color: "#909097" }
                }
              >
                Shopping Cart{" "}
                <span className={styles.count}>
                  {cart === null ? "0" : cart?.length}
                </span>
              </a>
            </li>
            <li
              className={`nav-item mx-3 ${styles.description_tab}`}
              role="presentation"
            >
              <a
                className={`${styles.tab_link}`}
                href="#wishlist"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                onClick={() => handleOnClick(1)}
                style={
                  active === 1 ? { color: "#23232c" } : { color: "#909097" }
                }
              >
                Wishlist{" "}
                <span className={styles.count}>
                  {wishes === null ? "0" : wishes?.length}
                </span>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="container-lg">
                {cart?.length ? (
                  <CartComponent />
                ) : (
                  <>
                    <p
                      className={`text-center mx-auto mb-5 ${styles.no_content}`}
                    >
                      No products in the cart.
                    </p>

                    <p className={`text-center mx-auto`}>
                      Tap below to Shop and Start Your List
                    </p>
                    <div className={`text-center mx-auto mb-5`}>
                      <button
                        className="btn btn-dark"
                        onClick={() => handleLinkClick()}
                      >
                        {clicked ? (
                          <div className="clearfix">
                            <div
                              className="spinner-border float-end"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          "Lets Get You What Need"
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="container justify-content-center">
                {wishes?.length ? (
                  <Wishlist />
                ) : (
                  <>
                    <p
                      className={`text-center mx-auto mb-5 ${styles.no_content}`}
                    >
                      No products in the Wish list.
                    </p>
                    <p className={`text-center mx-auto`}>
                      Tap below to Shop and Start Your List
                    </p>
                    <div className={`text-center mx-auto mb-5`}>
                      <button
                        className="btn btn-dark"
                        onClick={() => handleLinkClick()}
                      >
                        {clicked ? (
                          <div className="clearfix">
                            <div
                              className="spinner-border float-end"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          "Lets Get You What Need"
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
