import React, { useState } from "react";
import BlockContent from "@sanity/block-content-to-react";

import styles from "../../styles/Show.module.css";

const Description = ({ item }) => {
  const [active, setActive] = useState(0);

  const handleOnClick = (index) => {
    setActive(index); // remove the curly braces
  };

  return (
    <>
      <div className={`container`}>
        <ul
          className="nav nav-pills mb-3 justify-content-center"
          id="pills-tab"
          role="tablist"
        >
          <li
            className={`nav-item ${styles.description_tab}`}
            role="presentation"
          >
            <a
              className={`${styles.tab_link} active`}
              href="#description"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => handleOnClick(0)}
              style={active === 0 ? { color: "#23232c" } : { color: "#909097" }}
            >
              Specifications
            </a>
          </li>
          <li
            className={`nav-item ${styles.description_tab}`}
            role="presentation"
          >
            <a
              className={`${styles.tab_link}`}
              href="#additional info"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={() => handleOnClick(1)}
              style={active === 1 ? { color: "#23232c" } : { color: "#909097" }}
            >
              Additional information
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-8 col-md-offset-2">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th scope="row">Brand</th>
                        <td>{item?.brand}</td>
                      </tr>
                      <tr>
                        <th scope="row">Model</th>
                        <td>{item?.model}</td>
                      </tr>
                      <tr>
                        <th scope="row">SIM</th>
                        <td>{item?.sim}</td>
                      </tr>
                      <tr>
                        <th scope="row">Display Size</th>
                        <td>{item?.displaySize}</td>
                      </tr>

                      <tr>
                        <th scope="row">Processor</th>
                        <td>{item?.processor}</td>
                      </tr>
                      <tr>
                        <th scope="row">Memory</th>
                        <td>{item?.memory}</td>
                      </tr>
                      <tr>
                        <th scope="row">Storage</th>
                        <td>{item?.storage}</td>
                      </tr>
                      <tr>
                        <th scope="row">Operating System</th>
                        <td>{item?.os}</td>
                      </tr>
                      <tr>
                        <th scope="row">Rear Camera</th>
                        <td>{item?.rearCamera}</td>
                      </tr>
                      <tr>
                        <th scope="row">Front Camera</th>
                        <td>{item?.frontCamera}</td>
                      </tr>
                      <tr>
                        <th scope="row">Battery</th>
                        <td>{item?.battery}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="container justify-content-center col-12 col-sm-8 col-xs-5 p-2">
              <div className={`${styles.desc_p}`}>
                <BlockContent
                  blocks={item?.info || item?.body}
                  projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                  dataset={process.env.REACT_APP_SANITY_DATASET}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Description;
