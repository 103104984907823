import React, { useState } from "react";

import styles from "../styles/MobileDrawer.module.css";

const MobileDrawer = ({ cartNumber }) => {
  const [searchInput, setSearchInput] = useState("");
  const handleChange = (value) => {
    setSearchInput(value);
  };

  return (
    <div id="mobile-menu" className={styles.side_menu}>
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className={styles.side_menu_inner}>
        <form
          role="search"
          method="get"
          className={styles.search_form}
          action="/search"
        >
          <label>
            <span className={styles.sreen_reader_text}>Search for:</span>
            <input
              type="search"
              className={styles.search_field}
              placeholder="Search"
              value={searchInput}
              name="itemName"
              onChange={(e) => handleChange(e.target.value)}
            />
          </label>
          <button type="submit" className={styles.search_submit}>
            <span className={styles.sreen_reader_text}>Search</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
          <hr style={{ margin: 0 }} />
        </form>
        <nav className={styles.mobile_nav}>
          <ul className={styles.mobile_nav_ul}>
            <li className={styles.mobile_nav_li}>
              <a
                href="/"
                className={styles.side_menu_a}
                role="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Home
              </a>
            </li>
            <li className={styles.mobile_nav_li}>
              <a
                href="/computers"
                className={styles.side_menu_a}
                role="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Computers
              </a>
            </li>
            <li className={styles.mobile_nav_li}>
              <a
                href="/accessories"
                className={styles.side_menu_a}
                role="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Accessories
              </a>
            </li>
            <li className={styles.mobile_nav_li}>
              <a
                href="/equipment"
                className={styles.side_menu_a}
                role="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Equipment
              </a>
            </li>
            <li className={styles.mobile_nav_li}>
              <a
                href="/software"
                className={styles.side_menu_a}
                role="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Software
              </a>
            </li>
            <li className={styles.mobile_nav_li}>
              <a
                href="/promotions"
                className={styles.side_menu_a}
                role="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Promotions
              </a>
            </li>
          </ul>
        </nav>
        <div className={styles.mobile_menu_bottom}>
          <ul className={styles.mobile_nav_ul}>
            <li className={styles.mobile_menu_bottom_li}>
              <a
                href="/cart"
                className={styles.side_menu_a}
                role="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-cart"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>{" "}
                <span>Shopping Cart</span>
                <span className={styles.count}>{cartNumber}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileDrawer;
