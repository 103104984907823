import React from "react";
import { Link } from "react-router-dom";
import { useStateValue } from "../../config/StateProvider";

import styles from "../../styles/Cart.module.css";

const CartProduct = ({ cartItem }) => {
  const { id, slug, image, title, price, qty, hideButton } = cartItem;
  const [, dispatch] = useStateValue();
  const removeFromCart = (slug) => {
    //remove item from basket
    dispatch({
      type: "REMOVE_FROM_CART",
      slug: slug,
    });
  };
  return (
    <div
      className={`${styles.checkoutProduct} d-flex gap-1 flex-column flex-md-row`}
    >
      <div className={`m-2 px-auto`}>
        <Link to={`/product/${id}`}>
          <img
            className={`${styles.checkoutProduct__image}`}
            src={image}
            alt=""
          />
        </Link>
      </div>
      <div className={`${styles.checkoutProduct__info}`}>
        <p className={`${styles.checkoutProduct__title}`}>
          <Link to={`/product/${id}`}>{title}</Link>
        </p>
        <p className={`${styles.checkoutProduct__price}`}>
          Quantity:
          <strong>{qty}</strong>
        </p>
        <p className={`${styles.checkoutProduct__price}`}>
          Unit Cost: <small>UGX</small>{" "}
          <strong>{new Intl.NumberFormat().format(price)}</strong>
        </p>

        <p className={`${styles.checkoutProduct__price}`}>
          Total Cost: <small>UGX</small>{" "}
          <strong>{new Intl.NumberFormat().format(price * qty)}</strong>
        </p>
        {!hideButton && (
          <button
            className={`btn btn-danger`}
            onClick={() => removeFromCart(slug)}
          >
            Remove From Cart
          </button>
        )}
      </div>
    </div>
  );
};

export default CartProduct;
