import React, { useState } from "react";

import styles from "../../styles/Header.module.css";

const SearchModal = () => {
  const [searchInput, setSearchInput] = useState("");
  const handleChange = (value) => {
    setSearchInput(value);
  };

  return (
    <>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Search</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form method="GET" action="/search">
            <div className="modal-body">
              <div className={`row ${styles.searchFields}`}>
                <div className="col-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </div>
                <div className="col-11">
                  <input
                    type="text"
                    name="itemName"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => handleChange(e.target.value)}
                    className={`form-control ${styles.searchField}`}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex pb-3">
              <button type="submit" className="btn btn-secondary ms-auto me-3">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchModal;
