import React from "react";
import Layout from "../components/Layout/Layout";

const Faqs = () => {
  return (
    <Layout>
      <div className={`container col-12 col-md-8 justify-content-center mb-5`}>
        <div className={`m-5`}>
          <h2 className={`h1 fw-bold`}>Frequently Asked Questions</h2>
        </div>
        <div className={`m-3`}>
          <p className={`fw-normal`}>
            Below are some of the commonly asked questions from our Customers,
            for any other questions that are not among the listed below, kindly
            contact us through any of our contacts of your choice.
          </p>
          <p className={`fw-normal`}>We shall respond back to you</p>
        </div>
        <div className={`m-3`}>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  a) Where are you located?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We are situated in Kampala Capital City of Uganda, Central
                  Division, Nakasero along Bombo Road Opposite YMCA on Annex SA
                  Building, Basement Floor
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  b) What do I do if I receive an incorrect or faulty item?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We replace the Product for you with the exact product ordered
                  for and in good condition
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  c) I need to know details about your Warranty Policy
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  The Product Warranty period takes effect from the date of
                  sale. Client based Product damages listed below are not
                  covered by our Warranty Policy;
                  <br />
                  <div className="mt-3 ms-3">
                    <p>
                      {" "}
                      a) Product mishandling characterized with breakage and
                      associating the product to liquids
                    </p>
                    <p>
                      {" "}
                      b) Power Short-Circuit of the Product due to electric
                      surges and fluctuations while the product is plugged in
                      unstable electricity voltages.
                    </p>
                    <p>
                      {" "}
                      c) Repairs handled by other technicians other than Maricom
                      Technologies
                    </p>
                    <p>
                      {" "}
                      d) Battery short-life due to over-charging (Laptop kept on
                      plug at 100% battery Full Charge)
                    </p>
                    <p>e) Any other form of Product reckless handling.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  d) What Payment methods are available?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Payment is either cash on delivery or through mobile money
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  e) Are your products covered by a Warranty Period?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Yes please, all our products have a warranty period
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Faqs;
