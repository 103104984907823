import React from "react";

const Loading = ({ text }) => {
  return (
    <div className="half-square-box d-flex justify-content-center align-items-center">
      <div className="spinner-border me-2" role="status"></div> Loading {text}
      ...
    </div>
  );
};

export default Loading;
