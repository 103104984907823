import React, { useEffect, useState } from "react";

import SanityClient from "../../config/sanity";

import Product from "../../components/Product";
import Loading from "../../components/Layout/Loading";

import Layout from "../../components/Layout/Layout";
import styles from "../../styles/Products.module.css";

const Equipment = () => {
  const [products, setProducts] = useState(null);

  const getProducts = async () => {
    const query = `
      *['Equipment' in categories[]-> title]{
        _id,
        name,
        slug,
        price,
        stock,
        images,
        categories[] -> { slug }
    }`;

    await SanityClient.fetch(query).then((result) => setProducts(result));
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Layout>
      {/* <Head>
        <title>Equipment List I Maricom Technologies</title>
        <meta name="description" content="Find All your Tech Solutions Here" />
        <link rel="icon" href="/favi.png" />
      </Head> */}
      <div className="d-flex justify-content-center py-3">
        <h3 className={`${styles.page_title}`}>Equipment</h3>
      </div>
      <div className="d-flex justify-content-center col-md-9 mx-auto mb-3">
        <p className={`${styles.page_desc} text-center`}>
          Buy Computer Accessories from us at the comfort of your location. View
          our extensive collection of Computer Accessories that shall keep your
          Computer always upgraded with the latest accessories such as RAM
          Chips, Storage Drives, Keyboards among others.
        </p>
      </div>
      <hr />
      <div className={`row px-3`} style={{ margin: 0 }}>
        {products ? (
          products.map((data, i) => (
            <div className={`col-md-3`} key={i}>
              <Product product={data} />
            </div>
          ))
        ) : (
          <Loading text="Equipment" />
        )}
      </div>
    </Layout>
  );
};

export default Equipment;
