export const initialState = {
  cart: [],
  wishes: [],
  user: null,
};

// Selector
export const getCartTotal = (cart) =>
  cart?.reduce((amount, item) => item.total + amount, 0);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      if (state.cart === null) {
        return {
          ...state,
          cart: [action.item],
        };
      } else {
        return {
          ...state,
          cart: [...state.cart, action.item],
        };
      }

    case "ADD_TO_WISHES":
      if (state.wishes === null) {
        return {
          ...state,
          wishes: [action.item],
        };
      } else {
        return {
          ...state,
          wishes: [...state.wishes, action.item],
        };
      }

    case "SET_CART":
      return {
        ...state,
        cart: action.payload,
      };

    case "SET_WISHES":
      return {
        ...state,
        wishes: action.payload,
      };

    case "EMPYT_CART":
      return {
        ...state,
        cart: [],
      };

    case "REMOVE_FROM_CART":
      const index = state.cart.findIndex(
        (cartItem) => cartItem.slug === action.slug
      );

      let newcart = [...state.cart];
      if (index >= 0) {
        newcart.splice(index, 1);
      } else {
        console.warn(
          `Can't remove product (slug: ${action.slug}), it isn't in cart!`
        );
      }

      return {
        ...state,
        cart: newcart,
      };

    case "REMOVE_FROM_WISHES":
      const indice = state.wishes.findIndex(
        (cartItem) => cartItem.slug === action.slug
      );

      let newWishes = [...state.wishes];
      if (indice >= 0) {
        newWishes.splice(indice, 1);
      } else {
        console.warn(
          `Can't remove product (slug: ${action.slug}), it isn't in Wishlist!`
        );
      }

      return {
        ...state,
        wishes: newWishes,
      };

    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
};

export default reducer;
