import React, { useEffect, useState } from "react";

import SanityClient from "../../config/sanity";

import shuffle from "../../config/shuffle";

import Product from "../../components/Product";
import Loading from "../../components/Layout/Loading";

import Layout from "../../components/Layout/Layout";
import styles from "../../styles/Products.module.css";

const Computers = () => {
  const [products, setProducts] = useState(null);

  const getProducts = async () => {
    let compParams =
    "_id, slug, name, price, stalk, images, categories[] -> { slug }";
    let compSliceOperation = `{${compParams}} | order(_createdAt asc)`;

    const query = `*['Laptops' in categories[]-> title]${compSliceOperation}
                    + *['Desktops' in categories[]-> title]${compSliceOperation}
                  `;

    await SanityClient.fetch(query).then((result) => {
      result = shuffle(result);
      setProducts(result);
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Layout>
      <div className="d-flex justify-content-center py-3">
        <h3 className={`${styles.page_title}`}>Computers</h3>
      </div>
      <div className="d-flex justify-content-center col-md-9 mx-auto mb-3">
        <p className={`${styles.page_desc} text-center`}>
          Buy Computers from us at the comfort of your location. View our
          extensive collection of Computers that shall keep best suitable for your
          workloads such as Student, Office, Graphics Designing among others
        </p>
      </div>
      <hr />
      <div className={`row px-3`} style={{ margin: 0 }}>
        {products ? (
          products.map((comp, i) => (
            <div className={`col-md-3`} key={i}>
              <Product product={comp} link={`/computers`} />
            </div>
          ))
        ) : (
          <Loading text="computers" />
        )}
      </div>
    </Layout>
  );
};

export default Computers;
