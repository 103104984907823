import React from "react";
import { useStateValue } from "../../config/StateProvider";
import CartProduct from "./CartProduct";

import SubTotal from "./SubTotal";

const Cart = () => {
  const [{ cart }] = useStateValue();

  return (
    <>
      <div className={`row`}>
        <div className={`col-lg-8 col-md-12`}>
          {cart.length !== 0
            ? cart.map((item, i) => <CartProduct key={i} cartItem={item} />)
            : ""}
        </div>
        <div className={`col-lg-4 col-md-12`}>
          <SubTotal cart={cart} />
        </div>
      </div>
    </>
  );
};

export default Cart;
