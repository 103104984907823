import React from "react";

import Layout from "../components/Layout/Layout";
import AboutCarousel from "../components/AboutCarousel";

const About = () => {
  return (
    <Layout>
      <div className={`container col-12 justify-content-center mb-5`}>
        <div className={`m-5`}>
          <h2 className={`h1 fw-bold`}>About Us</h2>
        </div>
        <div className={`m-3`}>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="col-12 col-md-6 me-0 me-md-3">
              <p className={``}>
                Maricom Technologies Limited was officially registered under the
                Company’s Act in 2018 under the steer wheel of Ugandan
                Directors. Since 2018 to date, the Company has a strong position
                in the digital industry to be the Home of ICT Solutions. The
                Company has its registered Offices situated in Kampala Capital
                City Central Division, Nakasero along Bombo Road.
              </p>
              <p className={``}>
                At Maricom Technologies Limited, we are a digitally enterprise
                driven company providing ICT solutions that are scalable to
                future technology advancements and user friendly for the end
                use. Our 24/7 after Sales support services are provided to
                customers on the usage of products and services sold by us
                because our goal is always to deliver ICT Solutions that are in
                line with the mission, vision and objective of our customer.
              </p>
              <p className={``}>ICT Solutions we offer include among others</p>
              <p style={{ textIndent: "20px", marginTop: "-15px" }}>
                a) <strong>Retail sales</strong> for Laptops, Desktops,
                Accessories, Equipment and Software Applications and Websites.
              </p>
              <p style={{ textIndent: "20px", marginTop: "-15px" }}>
                b) <strong>Specialized Products and Services</strong> such as
                Networks, Repairs, Consultancy and Development of Enterprise
                Resource Planning Software Systems.
              </p>
              <p style={{ textIndent: "20px", marginTop: "-15px" }}>
                c) <strong>ICT Skills Training</strong> for career development
                in Specialties of Microsoft Office Applications, Software
                Development, Support, Networks and Computer Repairs.
              </p>
            </div>
            <div className={`col-12 col-md-6 me-0 me-md-3 mt-0`}>
              <h3 className="fw-bolder">Our Vision</h3>
              <p className={``}>
                To be the Market Leader in Offering ICT Solutions
              </p>
              <h3 className="fw-bolder">Our Mission</h3>
              <p className={``}>
                To deliver Solutions that Exceed Expectations
              </p>
              <h3 className="fw-bolder">Our Expertise</h3>
              <p style={{ textIndent: "20px", marginTop: "-5px" }}>
                a) <strong>Retail sales</strong> for Laptops, Desktops,
                Accessories, Equipment and Software Applications and Websites.
              </p>
              <p style={{ textIndent: "20px", marginTop: "-15px" }}>
                b) <strong>Specialized Products and Services</strong> such as
                Networks, Repairs, Consultancy and Development of Enterprise
                Resource Planning Software Systems.
              </p>
              <p style={{ textIndent: "20px", marginTop: "-15px" }}>
                c) <strong>ICT Skills Training</strong> for career development
                in Specialties of Microsoft Office Applications, Software
                Development, Support, Networks and Computer Repairs.
              </p>
              <h3 className="fw-bolder">Our Objective</h3>
              <p className={``}>
                To be among the biggest ICT Brands from Africa
              </p>
            </div>
          </div>

          <h3 className="fw-bolder">Why Choose Maricom Technologies</h3>
          <div className="d-flex flex-column flex-lg-row justify-content-between gap-3">
            <div className="me-0 me-md-3">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* item one  */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed bg-dark text-warning border-start border-4 border-warning"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Excellent After Sales Services
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show greyBody"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      We provide aftersales services for all our solutions,
                      therefore we are always with the customer
                      <br /> attending to inquiries raised by the customer in
                      usage of the product or software.
                    </div>
                  </div>
                </div>
                {/* item two */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed bg-success text-warning border-start border-4 border-dark"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Dynamic Solutions
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse greyBody"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Our Solutions are dynamic and always scalable to the
                      changing digital trends in Information Communication
                      Technology.
                    </div>
                  </div>
                </div>
                {/* item three */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed bg-success text-warning border-start border-4 border-dark"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      24/7 Customer Care Desk
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse greyBody"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      We are always available to offer Customer Care Services to
                      our Customers whenever they need our support through our
                      remotely offsite or onsite support services.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 ms-md-3">
              <div className="pictorial mt-3 mt-md-0">
                <AboutCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
