import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const SanityClient = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true,
  apiVersion: "2021-08-31",
});

const builder = imageUrlBuilder(SanityClient);

export function urlFor(source) {
  return builder.image(source);
}

export default SanityClient;

// import {
//   createClient,
//   createImageUrlBuilder,
//   createPreviewSubscriptionHook,
// } from "@sanity/client";

// const config = {
//   dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || "production",
//   projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
//   useCdn: process.env.NODE_ENV === "production",
//   apiVersion: "2021-08-31",
// };

// export const urlFor = (source) => createImageUrlBuilder(config).image(source);

// // Set up the client for fetching data in the getProps page functions
// export const sanityClient = createClient(config);

// export const usePreviewSubscription = createPreviewSubscriptionHook(config);
