import React, { useState } from "react";

import { urlFor } from "../../config/sanity";

import styles from "../../styles/Slider.module.css";

const Slider = ({ images }) => {
  const [image, setImage] = useState(images[0]?.asset?._ref);
  return (
    <>
      <div className={`${styles.top}`}>
        <div className={`${styles.container}`}>
          {/* Thumbnails */}
          <ul className={`${styles.thumbnails}`}>
            {images && images[0] && (
              <li className={`${styles.thumbnails_li}`}>
                <span
                  onClick={() => setImage(images[0]?.asset?._ref)}
                  className={`${styles.thumbnails_a}`}
                >
                  <img
                    src={urlFor(images[0]?.asset?._ref).url()}
                    alt="..."
                    width="50px"
                    height="50px"
                    className={`${styles.thumbnails_img}`}
                  />
                </span>
              </li>
            )}
            {images && images[1] && (
              <li className={`${styles.thumbnails_li}`}>
                <span
                  onClick={() => setImage(images[1]?.asset?._ref)}
                  className={`${styles.thumbnails_a}`}
                >
                  <img
                    src={urlFor(images[1]?.asset?._ref).url()}
                    alt="..."
                    width="50px"
                    height="50px"
                    className={`${styles.thumbnails_img}`}
                  />
                </span>
              </li>
            )}
            {images && images[2] && (
              <li className={`${styles.thumbnails_li}`}>
                <span
                  onClick={() => setImage(images[1]?.asset?._ref)}
                  className={`${styles.thumbnails_a}`}
                >
                  <img
                    src={urlFor(images[2]?.asset?._ref).url()}
                    alt="..."
                    width="50px"
                    height="50px"
                    className={`${styles.thumbnails_img}`}
                  />
                </span>
              </li>
            )}
          </ul>

          {/* Slides */}
          <ul className={`${styles.slides}`}>
            <li className={`${styles.slides_li}`}>
              <img
                src={urlFor(image).url()}
                layout="fill"
                alt="..."
                className={`${styles.slides_img}`}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Slider;
