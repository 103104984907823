import React, { useEffect, useState } from "react";

import SanityClient from "../../config/sanity";

import Product from "../../components/Product";
import Loading from "../../components/Layout/Loading";

import Layout from "../../components/Layout/Layout";
import styles from "../../styles/Products.module.css";

const Software = () => {
  const [products, setProducts] = useState(null);

  const getProducts = async () => {
    const query = `
      *['Software' in categories[]-> title]{
        _id,
        name,
        slug,
        price,
        images,
        stock,
        categories[] -> { slug }
    }`;

    await SanityClient.fetch(query).then((result) => setProducts(result));
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Layout>
      {/* <Head>
        <title>Software List I Maricom Technologies</title>
        <meta name="description" content="Find All your Tech Solutions Here" />
        <link rel="icon" href="/favi.png" />
      </Head> */}
      <div className="d-flex justify-content-center py-3">
        <h3 className={`${styles.page_title}`}>Software</h3>
      </div>
      <div className="d-flex justify-content-center col-md-9 mx-auto mb-3">
        <p className={`${styles.page_desc} text-center`}>
          Buy Software Applications from us at the comfort of your location.
          View our extensive collection of Software Applications that shall
          solve your digital needs in Graphics Designing with Adobe
          Applications, Office workloads with Microsoft Office, digital presence
          on the World wide Web with Websites among others.
        </p>
      </div>
      <hr />
      <div className={`row px-3`} style={{ margin: 0 }}>
        {products ? (
          products.map((data, i) => (
            <div className={`col-md-3`} key={i}>
              <Product product={data} />
            </div>
          ))
        ) : (
          <Loading text="Software products" />
        )}
      </div>
    </Layout>
  );
};

export default Software;
