import React, { useEffect } from "react";
import Glide from "@glidejs/glide";

import Product from "./Product";

import styles from "../styles/glide.module.css";
import themestyles from "../styles/glidertheme.module.css";

const ProductCarousel = ({ items, id }) => {
  useEffect(() => {
    const slider = new Glide(`.glide_${id}`, {
      type: "carousel",
      autoplay: 2000,
      hoverpause: true,
      perView: 5,
      breakpoints: {
        1440: {
          perView: 4,
        },
        1024: {
          perView: 3,
        },
        720: {
          perView: 2,
        },
        425: {
          perView: 1,
        },
      },
    });

    slider.mount();

    // cleanup when unmounting component
    return () => slider.destroy();
  }, [id]);

  return (
    <div className={`${styles.glide} glide_${id}`}>
      <div
        className={`${styles.glide__track} glide__track`}
        data-glide-el="track"
      >
        <ul className={`${styles.glide__slides} glide__slides`}>
          {items &&
            items.map((item, i) => (
              <li key={i} className={`${styles.glide__slide} glide__slide`}>
                <Product product={item} />
              </li>
            ))}
        </ul>
      </div>
      <div
        className={`${styles.glide__arrows} glide__arrows`}
        data-glide-el="controls"
      >
        <button
          className={`${themestyles.glide__arrow} ${themestyles.glide__arrow_left} glide__arrow glide__arrow--left`}
          data-glide-dir="<"
        >
          {"<"}
        </button>
        <button
          className={`${themestyles.glide__arrow} ${themestyles.glide__arrow_right} glide__arrow glide__arrow--right`}
          data-glide-dir=">"
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default ProductCarousel;
