import React from "react";
import WishProduct from "./WishProduct";

import { useStateValue } from "../../config/StateProvider";

const Wishlist = () => {
  const [{ wishes }] = useStateValue();
  return (
    <>
      <div className={`row justify-content-center`}>
        <div className={`col-md-8`}>
          {wishes.map((item, i) => (
            <WishProduct
              key={i}
              slug={item.slug}
              id={item.id}
              title={item.title}
              image={item.image}
              price={item.price}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Wishlist;
