import React from "react";
import ProductCarousel from "./ProductCarousel";

import styles from "../styles/Home.module.css";

const ProductSection = ({ title, items, id }) => {
  return (
    <div className={`mb-5`}>
      <h2 className={`text-center text-uppercase ${styles.hm_heading}`}>
        {title}
      </h2>
      <div>
        <ProductCarousel items={items} id={id} />
      </div>
      <div className={`w-25 mx-auto text-center`}>
        <a
          href={id ? `/${id}` : "#!"}
          className={`btn btn-dark text-uppercase`}
        >
          <strong>SEE ALL {title}</strong>
        </a>
      </div>
    </div>
  );
};

export default ProductSection;
