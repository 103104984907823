import React, { useEffect, useRef, useState } from "react";
import { useStateValue } from "../config/StateProvider";
import { useNavigate, Link } from "react-router-dom";

// ES2015 module syntax
import { PDFExport } from "@progress/kendo-react-pdf";
import Layout from "../components/Layout/Layout";

// Order Function
import createOrder from "../actions/order";

const Invoice = () => {
  const [cart, setCart] = useState([]);
  const [user, setUser] = useState({});
  const [grandTotal, setGrandTotal] = useState(0);
  const [checked, setChecked] = useState(false);
  const [invoice, setInvoice] = useState("");

  const today = new Date().toDateString();

  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const pdfExportComponent = useRef(null);

  useEffect(() => {
    // get cart and user from localStorage
    const cart = JSON.parse(localStorage.getItem("maricom-tech-cart"));
    const user = JSON.parse(localStorage.getItem("maricom-tech-user-info"));
    setCart(cart);
    setUser(user);
  }, []);

  useEffect(() => {
    let grandTotal = 0;
    for (let i = 0; i < cart.length; i += 1) {
      grandTotal += cart[i]?.price * cart[i]?.qty;
    }
    setGrandTotal(grandTotal);
  }, [cart]);

  useEffect(() => {
    const date = new Date();
    setInvoice(
      `${Math.floor(Math.random() * 1000)}${date.getDate()}${
        date.getMonth() + 1
      }${date.getFullYear()}`
    );
  }, []);

  const handleOrder = () => {
    setChecked(true);

    const orderDetails = {
      user: user,
      invoiceNumber: invoice,
      invoiceDate: today,
      order: cart,
    };

    orderDetails.order.forEach((element) => {
      element._key = `key_${Math.floor(Math.random() * 9876 + 1).toString()}`;
      delete element.image;
    });

    createOrder(orderDetails);

    handleExportWithComponent();

    dispatch({
      type: "EMPTY_CART",
    });

    localStorage.setItem("maricom-tech-cart", JSON.stringify([]));

    navigate("/");
    setTimeout(() => {
      alert("Order Successfully Made.");
    }, 3000);
  };

  function handleExportWithComponent() {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  }

  return (
    <Layout>
      <div className="container justify-content-center">
        {/* <Head>
          <title>Invoice I Maricom Technologies</title>
          <meta
            name="description"
            content="Find All your Tech Solutions Here"
          />
          <link rel="icon" href="/favi.png" />
        </Head> */}
        <div className="d-flex flex-sm-row flex-column col-9 my-2 mx-auto ">
          <div className={`col m-3`}>
            <Link to="/cart" className="btn btn-dark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-backspace-fill me-1"
                viewBox="0 0 16 16"
              >
                <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
              </svg>
              Edit Order
            </Link>
          </div>

          {cart && (
            <div className={`col m-3`}>
              <button
                className="btn btn-primary float-sm-end"
                onClick={() => handleExportWithComponent()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-cloud-download-fill me-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"
                  />
                </svg>
                Download Invoice
              </button>
            </div>
          )}

          {cart && (
            <div className={`col m-3`}>
              <button
                className="btn btn-success float-sm-end"
                onClick={() => handleOrder()}
              >
                {checked ? (
                  <div className="clearfix">
                    <div className="spinner-border float-end" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-bag-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z" />
                    </svg>
                    Order
                  </span>
                )}
              </button>
            </div>
          )}
        </div>

        <div className="col-9 my-2 mx-auto bg-light p-3" id="invoicePdf">
          <PDFExport
            ref={pdfExportComponent}
            paperSize="A4"
            margin={40}
            fileName={`Maricom Invoice for ${
              user?.fullName
            } ${new Date().getFullYear()}`}
            author="Maricom Tech"
          >
            <p className="text-center h3">Maricom Technologies Limited</p>
            <p className="text-center h3">Online Tech-Shop</p>
            <p className="text-center h3">
              Tax Invoice for Delivery of Supplies
            </p>
            <p className="text-center">URA TIN: 1016132437</p>

            <div className={`d-flex flex-sm-row flex-column`}>
              <div className={`col`}>
                INV No: <p>{invoice}</p>
              </div>
              <div className={`col`}>
                Date: <p>{today}</p>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered border-dark">
                <thead>
                  <tr>
                    <th scope="row" colSpan="6">
                      M/S: <p>{user?.fullName} </p>
                      Delivery Address: <p>{user?.location}</p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan="6">
                      Supplies Description:
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan="6">
                      Delivery Costs:{" "}
                      <p>
                        {user?.officeDelivery
                          ? "No Additional Costs. Pick up product(s) from our Offices."
                          : `Additional Costs Due to delivery to ${user?.location}`}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">SR No.</th>
                    <th scope="col">Item Definition</th>
                    <th scope="col">Unit Measure</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Unit Price (UGX)</th>
                    <th scope="col">Grand Total (UGX)</th>
                  </tr>
                </thead>
                <tbody>
                  {cart &&
                    cart.map((item, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td className="text-wrap">{item?.title}</td>
                        <td>Pieces</td>
                        <td>{item?.qty}</td>
                        <td>{new Intl.NumberFormat().format(item?.price)}</td>
                        <td>{new Intl.NumberFormat().format(item?.total)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="row" colSpan="5">
                      Grand Total:
                    </th>
                    <td>
                      <span>{new Intl.NumberFormat().format(grandTotal)}</span>{" "}
                      /=
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-dark">
                <tbody>
                  <tr>
                    <th scope="row" style={{ padding: 0 }}>
                      <div
                        className={`d-flex flex-sm-row flex-column text-white bg-success`}
                      >
                        <div className={`col-sm-1 ps-4`}>a)</div>
                        <div className={`col-sm-11 px-2 py-1`}>
                          Delivery Costs apply for supplies delivered to the
                          Customer address pickup location while No Delivery
                          Costs applied for Purchase Orders picked by the
                          Customer from our Offices. Check out our delivery
                          costs in town centres at{" "}
                          <a
                            href="https://tech-shop.maricomtech.com/delivery-info"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://tech-shop.maricomtech.com/delivery-info
                          </a>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" style={{ padding: 0 }}>
                      <div
                        className={`d-flex flex-sm-row flex-column text-white bg-success`}
                      >
                        <div className={`col-sm-1 ps-4`}>b)</div>
                        <div className={`col-sm-11 px-2 py-1`}>
                          Payment is either cash on delivery or through Mobile
                          Money to our field delivery Sales Person, however for
                          Mobile Money Payment, the Customer is called upon to
                          include withdraw charges as an addition to the Tax
                          Invoice Amount.
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <h4>Delivered by:</h4>
                      <div className={`d-flex flex-sm-row flex-column`}>
                        <div className={`col`}>
                          <p>Names:</p>
                        </div>
                        <div className={`col`}>
                          <p>Signature: </p>
                        </div>
                        <div className={`col`}>
                          <p>Date:</p>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <h4>Received by:</h4>
                      <div className={`d-flex flex-sm-row flex-column`}>
                        <div className={`col`}>
                          <p>Names:</p>
                        </div>
                        <div className={`col`}>
                          <p>Signature: </p>
                        </div>
                        <div className={`col`}>
                          <p>Date:</p>
                        </div>
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
              <div className="container">
                <p className="h3">Note</p>
                <p className="mx-3">
                  Delivery Costs apply for Deliveries to Customers addresses and
                  No delivery Costs apply for Products delivered to Customers
                  from our Offices.
                </p>
              </div>
            </div>
          </PDFExport>
        </div>
      </div>
    </Layout>
  );
};

export default Invoice;
