import React from "react";

const AboutCarousel = () => {
  return (
    <div
      id="carouselExampleSlidesOnly"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner imagePadding">
        <div className="carousel-item active">
          <img
            className={`mx-auto`}
            src="/laptops.jpg"
            alt="..."
            width={400}
            height={210}
          />
        </div>
        <div className="carousel-item">
          <img
            className={`mx-auto`}
            src="/desktops.jpg"
            alt="..."
            width={400}
            height={210}
          />
        </div>
        <div className="carousel-item">
          <img
            className={`mx-auto`}
            src="/netAccessories.jpg"
            alt="..."
            width={400}
            height={210}
          />
        </div>
        <div className="carousel-item">
          <img
            className={`mx-auto`}
            src="/compAccessories.jpg"
            alt="..."
            width={400}
            height={210}
          />
        </div>
        <div className="carousel-item">
          <img
            className={`mx-auto`}
            src="/software.jpg"
            alt="..."
            width={400}
            height={210}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutCarousel;
